<!--表单设置-->
<template>
    <div class="form-setting">
        <el-tabs v-model="activeFormId" @tab-click="changeForm">
            <el-tab-pane
                v-for="item in formList"
                :label="item.FormName||item.FormAliasName"
                :name="item.FormId"
                :key="item.FormId"
            >
            </el-tab-pane>
        </el-tabs>
        <el-table
            border
            stripe
            :data="formConfig"
            ref="multipleTable"
            v-loading="loading"
        >
            <el-table-column
                label="办理环节"
                prop="PhaseId"
                align="center"
            >
                <template slot-scope="scope">
                    {{ segmentMap[scope.row.PhaseId].PhaseName }}
                </template>
            </el-table-column>
            <el-table-column
                label="只读"
                prop="IsRead"
                align="center"
            >
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.IsRead"
                        active-text="关闭"
                        inactive-text="开启"
                        :width="50"
                        @change="saveFormConfig(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                label="显示保存过的表单"
                prop="IsShowSavedForm"
                align="center"
            >
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.IsShowSavedForm"
                        active-text="关闭"
                        inactive-text="开启"
                        :width="50"
                        @change="saveFormConfig(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                label="显示"
                prop="IsVisible"
                align="center"
            >
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.IsVisible"
                        active-text="关闭"
                        inactive-text="开启"
                        :width="50"
                        @change="saveFormConfig(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'form-setting',
    data() {
        return {
            activeFormId: '', // 当前选中得表单
            formList: [], // 表单列表
            formConfig: [],
            segmentMap: {}, // 环节map

            loading: false,
        };
    },
    props: {
        businessid: String, // 流程id
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.getSegmentInfo();
            this.getFormList();
        },
        // 切换表单
        changeForm() {
            this.getFormConfig();
        },
        // 获取表单列表
        async getFormList() {
            const data = await this.$axios.get(`/interfaceApi/basicplatform/simpleflow/config/getbusinessformlist?businessid=${this.businessid}`);
            this.formList = data || [];
            // 默认选中第一个
            if (this.formList.length > 0) {
                this.activeFormId = this.formList[0].FormId;
                this.getFormConfig();
            }
        },
        // 获取表单配置
        async getFormConfig() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/basicplatform/simpleflowphase/config/getformphaseconfig?'
                + `businessid=${this.businessid}&formid=${this.activeFormId}`);
            this.formConfig = data;
            this.loading = false;
        },
        // 获取流程所有环节信息
        getSegmentInfo() {
            this.$axios.get('/interfaceApi/basicplatform/simpleflowphase/config/getphaselist?businessid=' + this.businessid)
                .then(data => {
                    const obj = {};
                    data.forEach(item => {
                        obj[item.PhaseId] = item;
                    });
                    this.segmentMap = obj;
                });
        },
        // 保存表单配置
        saveFormConfig(row) {
            row.IsCompleteControl = !row.IsRead;
            this.$axios.post('/interfaceApi/basicplatform/simpleflowphase/config/saveformphaseconfig', [row])
                .then(() => {
                    return null;
                }).catch(e => {
                    this.$message(e.ErrorCode.Message);
                });
        },

    },
};
</script>

<style lang="stylus" scoped>
.form-setting
    box-sizing border-box
    width 100%;
    height 100%;
    background #fff;
    padding 0.2rem;
    .el-table
        >>> th
            background: #EDF0F5;
            border: 1px solid #DEDFE0;
            color #022782
        th,td
            padding 0
        >>> .el-switch__label
            display inline-block;
            &.el-switch__label--right
                left: 0.1rem;
                position: absolute;
                z-index: 9;
            &.el-switch__label--left
                right: 0.1rem;
                position: absolute;
                z-index: 9;
            &.is-active
                display none
                color #fff;
            span
                color #fff;
                font-size 0.12rem;
    >>> .el-tabs
        .el-tabs__item
            font-size 0.16rem!important;
            padding 0 0.25rem!important;
            &:hover,&.is-active
                color #D6000F!important;
        .el-tabs__nav-wrap::after
            height 1px!important;
            background-color: #F0F0F0!important;
        .el-tabs__active-bar
                box-sizing: content-box!important;
                background-color: #D6000F!important;
</style>
